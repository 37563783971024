import * as React from "react";
import {useEffect, useState} from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import ProductDisplay from "./ProductDisplay";
import {model, product_model} from "../../api/general";
import AllDisplay from "./AllDisplay";
import {red} from '@mui/material/colors';
import {useSearchParams} from "react-router-dom";


interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

const color = red[500];

function TabPanel(props: TabPanelProps) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{pt: 5, pb: 5}}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

export default function Taskbar({
                                    part_type,
                                    brand_id,
                                    model_slug,
                                    brand_slug,
                                    search,
                                    tabIndex,
                                    setTabIndex,
                                    modelData,
                                    categoryId
                                }) {

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabIndex(newValue);
    };

    const [dataModels, setDataModels] = useState([]);
    const [brandId, setBrandId] = useState(brand_id);
    const [searchParams] = useSearchParams();


    const getBrandData = async () => {
        const data = await model({brand_slug: searchParams.get("brand-slug")})
        setBrandId(data.data.data.id)
    }

    const getUsers = async () => {
        if (brand_slug || brandId || brand_id) {
            if (brand_slug && !brandId) {
                getBrandData()
            } else {

                // console.log(brandId)
                // console.log(brand_id)
                const {data} = await product_model({
                    part_type: part_type,
                    brand_id: brand_id ? brand_id : brandId,
                    category_id: categoryId || ""
                });
                setDataModels(data.data)
                if (model_slug) {
                    // console.log(dataModels)
                    const v = data.data.findIndex(ind => ind.slug === model_slug)
                    setTabIndex(v >= 0 ? (v + 1) : 0)
                }
            }
        }
        // if (search != null) {
        if (search) {
            setTabIndex(0);
        }
        // console.log(tabIndex);

    };

    useEffect(() => {
        setDataModels([]);
        getUsers();
    }, [searchParams.get("brand-slug"), brandId, brand_id, categoryId]);

    return (
        <>
            <div className="shop-tabs">
                <Box sx={{width: "92%"}}>
                    <div className="shop-tab">
                        <Box>
                            <Tabs
                                className="ter"
                                value={tabIndex}
                                onChange={handleChange}
                                variant="scrollable"
                                allowScrollButtonsMobile
                                textColor={color}
                                TabIndicatorProps={{
                                    style: {
                                        backgroundColor: "#ef5350"
                                    }
                                }}
                                aria-label="basic tabs example"
                            >
                                <Tab className="ter" label="All" {...a11yProps(0)} />

                                {/*{false ? <Tab className="ter" label={dataModels.title} {...a11yProps(1)} sel/> :}*/}
                                {dataModels.map((element, idx) => {
                                    return (
                                        <Tab key={idx} className="ter" label={element.title} {...a11yProps(idx + 1)} />
                                    );
                                })}
                            </Tabs>
                        </Box>
                    </div>
                    <TabPanel value={tabIndex} index={0}>
                        <AllDisplay categoryId={categoryId} modelData={modelData} part_type={part_type}
                                    brand_id={brand_id || brandId || 0} search={search}
                                    slug_brand={brand_slug}/>
                    </TabPanel>
                    {dataModels.map((element, idx) => {
                        return (
                            <TabPanel value={tabIndex} index={idx + 1} key={idx + 1}>
                                <ProductDisplay categoryId={categoryId} modelData={modelData} part_type={part_type}
                                                brand_id={brand_id} model_id={element.id}/>
                            </TabPanel>
                        );
                    })}
                </Box>
            </div>

        </>
    );
}
