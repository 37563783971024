import {CCardBody} from "@coreui/react";
import {Card, Col, Row} from "react-bootstrap";
import React from "react";
import banner from "../../asset/user3.avif";
import {BsTelephone} from "react-icons/bs";
import {Rating} from "@mui/material";
import ModalAddReview from "./ModalAddReview";

export function BookingServiceInfoCard({dataInfo, serviceInfo, setReloadData}) {
    const style = {color: "var(--primary)", fontSize: "1.2em"}
    return (
        <Card className={'shadow w-100'}>
            <CCardBody>
                <Row xs={{cols: 1}} className={'g-3'}>
                    <Col xs={12}> <span className="clientDetail">Service Engineer</span></Col>
                    <Col xs={12}>
                        <div className={'d-flex flex-row'}>
                            <div><img className={"rounded-circle mx-2"}
                                      src={serviceInfo.url_profile ? serviceInfo.url_profile : banner}
                                      alt="user" width={"50px"} height={"50px"} onError={(e) => e.target.src = banner} /></div>
                            <div className="customerDetail">
                                <span className="names">{serviceInfo?.name}</span>
                                <div className="phoneNumber">
                                    <div>+91 {serviceInfo?.mobile}</div>
                                </div>

                            </div>
                            <div>
                                <a href={"tel:" + (serviceInfo?.mobile || "")} className={''}>
                                    <BsTelephone style={style}
                                                 className={'align-items-center justify-content-center'}/>
                                </a>
                            </div>
                        </div>
                    </Col>
                    <Col xs={12}>
                        {dataInfo?.status === 'repair_success' || dataInfo?.status === 'completed' || dataInfo?.status === 'delivery_success' ?
                            (
                                serviceInfo?.user_review ? <><Rating size={"small"} readOnly
                                                                     name="simple-controlled"
                                                                     value={serviceInfo?.user_review?.count_rating || 0}
                                                                     defaultValue={serviceInfo?.user_review?.count_rating || 0}
                                                                     precision={0.5}/></> :
                                    <ModalAddReview token_ref={serviceInfo?.token} ref_type={'agent'}
                                                    reviewFor={'order'} tokenReviewFor={dataInfo?.token}
                                                    dataInfo={dataInfo}
                                                    setReloadData={setReloadData}/>
                            )
                            : <> </>
                        }
                    </Col>
                </Row>
            </CCardBody>
        </Card>
    );
}