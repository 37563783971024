import React, {useState, useEffect} from "react";
import {Card, Col, Container, Row} from "react-bootstrap";
import {Skeleton} from "@mui/material";
import {Swiper, SwiperSlide} from 'swiper/react';

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

// import required modules
import {Autoplay, Pagination, Navigation} from 'swiper/modules';
import {useAuth} from "../../hooks";
import {ApiCall} from "../../api/HelpingMethod";
import {BlogItemCard} from "./BlogItemCard";

const RelatedBlog = () => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([])
    const [blogModel, setBlogModel] = useState(['Pagination'])
    const {authInfo} = useAuth();

    const [bodyData, setBodyData] = useState({
        "login_user_id": authInfo.profile.id,
        "login_user_type": authInfo.profile.user_type,
        "limit": 6,
        "offset": 0
    })


    const getData = async () => {
        setLoading(true)
        const response = await ApiCall("dealer/blog", bodyData)
        // console.log("response----",response)
        if (response?.error === false) {
            setData(response?.data)

            if (response?.data.length < 3) {
                setBlogModel([...blogModel, 'Pagination', 'Autoplay'])
            } else {
                setBlogModel(['Pagination'])

            }


        } else {
            setData([])
        }
        setLoading(false)
    }

    useEffect(() => {
        getData()
    }, [])

    const styleCardHeight = {height: "550px"};

    const SkeletonRelatedBlog = ({titleDescriptionWidth, cardImageWidth, titleWidth}) => {
        return (
            <>
                <Col md={4} className="mb-3">
                    <Card className="shadow mx-3" style={styleCardHeight}>
                        <Card.Body>
                            <Skeleton variant="rectangle" className="rounded" width={cardImageWidth} height={250}/>
                            <Card.Text className="fw-bold mt-3">
                                <Skeleton variant="rectangle" className="rounded mt-3" width={titleDescriptionWidth}
                                          height={30}/>
                            </Card.Text>
                            <Skeleton variant="rectangle" className="rounded mt-3" width={titleDescriptionWidth}
                                      height={20}/>
                            <Skeleton variant="rectangle" className="rounded mt-3" width={titleWidth} height={20}/>
                            <Skeleton variant="rectangle" className="rounded mt-3" width={100} height={20}/>

                            <div className="d-flex mt-3">
                                <Skeleton variant="circular" className="" width={20} height={20}/>
                                <Skeleton variant="rectangle" className="rounded mx-2" width={20} height={20}/>
                                <Skeleton variant="rectangle" className="rounded mx-3" width={20} height={20}/>
                                <Skeleton variant="rectangle" className="rounded mx-3" width={100} height={20}/>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </>
        );
    };

    let titleDescriptionWidth;
    let cardImageWidth;
    let titleWidth;
    const numberOfItems = 3

    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: {max: 4000, min: 3000},
            items: 5
        },
        desktop: {
            breakpoint: {max: 3000, min: 1024},
            items: 3
        },
        tablet: {
            breakpoint: {max: 1024, min: 464},
            items: 2
        },
        mobile: {
            breakpoint: {max: 464, min: 0},
            items: 1
        }
    };


    return (
        <div>
            <Container className="mb-5">
                <Row className="h3 mt-4 fw-bold mx-3">Related Blogs</Row>
                <br/>
                <Row>
                    {loading ? (
                            Array.from({length: numberOfItems}, (_, index) => (
                                <SkeletonRelatedBlog titleDescriptionWidth={titleDescriptionWidth}
                                                     cardImageWidth={cardImageWidth}
                                                     titleWidth={titleWidth} key={index}/>
                            ))
                        )
                        :
                        <>
                            <Swiper
                                slidesPerView={4}
                                centeredSlides={true}
                                loop={true}
                                autoplay={{
                                    delay: 2500,
                                    disableOnInteraction: false,
                                    stopOnLastSlide: false,
                                }}
                                pagination={{
                                    clickable: true,
                                }}
                                navigation={true}
                                modules={[Autoplay, Pagination, Navigation]}
                                breakpoints={{
                                    // when window width is >= 1200px (xl)
                                    1200: {
                                        slidesPerView: 3,
                                    },
                                    // when window width is >= 992px (lg) and < 1200px (xl)
                                    992: {
                                        slidesPerView: 3,
                                    },
                                    // when window width is >= 768px (md) and < 992px (lg)
                                    768: {
                                        slidesPerView: 2,
                                    },
                                    // when window width is >= 576px (sm) and < 768px (md)
                                    576: {
                                        slidesPerView: 1,
                                    },
                                    // when window width is < 576px (xs)
                                    0: {
                                        slidesPerView: 1,
                                    },
                                }}
                            >
                                {
                                    data && data.map((blog, index) => {
                                        return (
                                            <SwiperSlide key={index} className={"p-3 text-start"}>
                                                <BlogItemCard data={blog}/>
                                            </SwiperSlide>
                                        )
                                    })
                                }
                            </Swiper>
                        </>
                    }
                </Row>
            </Container>
        </div>
    );
};
export default RelatedBlog;

